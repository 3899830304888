import Link from 'next/link'
import { LinkButton } from 'components/LinkButton'
import styles from './404.module.scss'

export default function Custom404() {
  return (
    <div className={styles.container}>
      <h1>Page not found</h1>
      <Link href="/" passHref>
        <LinkButton>Back to Home</LinkButton>
      </Link>
    </div>
  )
}
